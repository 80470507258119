import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';

import WalkathonComp from '../components/WalkathonComp'
import ChildCareComp from '../components/ChildCareComp'
import ParentInfoComp from '../components/ParentInfoComp'
import SpouseInfoComp from '../components/SpouseInfoComp'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


var gFeeEnabled = {};
export default function TshirtInfoForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        spouse: {},
        children: [],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [{
            shirtSize: "",
            shirtQuantity: "0"
        }],
        eventType: 'TshirtWalkathon',
        eventTypeId: 4,
        center: '',
        notes: '',
        sessionName: ""
    });

    const [tempData, setTempData] = useState({
        addGuest: false,
        addPeople: false,
        noOfPeople: false
    });

    const [inputFields, setInputFields] = useState(eventData.transaction);

    //Walkathon data
    const [addGuest, setAddGuest] = useState(tempData.addGuest);
    const [addPeople, setAddPeople] = useState(tempData.addPeople);
    const [guestFields, setGuestFields] = useState(eventData.guest);
    const [noOfPeople, setNoOfPeople] = useState(tempData.noOfPeople);

    const ticketPrice = process.env.REACT_APP_TICKET_PRICE;
    const tshirtPrice = process.env.REACT_APP_TSHIRT_PRICE;
    const picnicPrice = process.env.REACT_APP_WALKATHON_PRICE;

    const [noOfTickets, setNoOfTickets] = useState(0);
    const [tshirtFlag, setTshirtFlag] = useState(false);
    const [holiFlag, setHoliFlag] = useState(true);
    //const [noOfShirts, setNoOfShirts] = useState(eventData.tshirts.shirtQuantity);
    const [noOfShirts, setNoOfShirts] = useState(0);
    const [quantities, setQuantities] = useState(eventData.transaction.quantities)
    var tickets;
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [feeEnabled, setFeeEnabled] = useState(0);
    const [shirtFields, setShirtFields] = useState(eventData.tshirts);

    const [addVolunteer, setAddVolunteer] = useState();

    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
        gFeeEnabled = feeEnabled;
    }
    total();

    //console.log("T-shirt form data:", eventData, tempData);

    function volunteerSwitchHandler(e) {
        setAddVolunteer(e.target.checked);
    };
    const handleShirtSize = (e, index) => {
        let newForm = [...shirtFields];
        newForm[index][e.target.name] = e.target.value;
        setShirtFields(newForm);
        eventData.tshirts = newForm;
    }

    const handleShirtQuantity = (e, index) => {
        let newForm = [...shirtFields];
        newForm[index][e.target.name] = e.target.value;
        setShirtFields(newForm);
        eventData.tshirts = newForm;
        setNoOfShirts(e.target.value);
        let dummy = 0;
        eventData.tshirts.forEach((element) => { dummy = parseInt(dummy) + parseInt(element.shirtQuantity) }
        );

        setNoOfShirts(dummy);
    }

    const handleAddShirtFields = () => {
        setShirtFields([...shirtFields, { shirtSize: "", shirtQuantity: "" }]);
    };

    const handleRemoveShirtFields = (index) => {
        const values = [...shirtFields];
        values.splice(index, 1);
        setShirtFields(values);
        eventData.tshirts = values;
        let dummy = 0;
        eventData.tshirts.forEach((element) => { dummy = parseInt(dummy) + parseInt(element.shirtQuantity) }
        );

        setNoOfShirts(dummy);
    };

    // Walkathon functions start
    useEffect(() => {
        setNoOfPeople(0);
        setAddGuest(false);
        setGuestFields("");
    }, [addPeople])

    useEffect(() => {
        setAddGuest(false);
    }, [noOfPeople <= 1])

    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = picnicPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfShirts) * parseInt(tshirtPrice) + parseInt(noOfPeople) * parseInt(picnicPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfShirts) * parseInt(tshirtPrice) + parseInt(noOfPeople) * parseInt(picnicPrice) + parseInt(donation)) * 1.00);
        }
        tempData.addGuest = addGuest;
        tempData.addPeople = addPeople;
        tempData.noOfPeople = noOfPeople;

        eventData.transaction.totalAmount = totalAmount;
        console.log("use effect 2 :", totalAmount, noOfShirts, noOfTickets, eventData);
    }, [noOfPeople, noOfShirts, donation, feeEnabled])

    function handlePeople(e) {
        setNoOfPeople(parseInt(e.target.value));
    };
    function peopleSwitchHandler(e) {
        setAddPeople(e.target.checked);
    };

    const handleAddFields = () => {
        setGuestFields([...guestFields, { memberType: "Guest", firstName: "", lastName: "", mobileNumber: "" }]);
    };

    const handleRemoveFields = (index) => {
        const values = [...guestFields];
        values.splice(index, 1);
        setGuestFields(values);
        eventData.guest = values;
    };

    const handleGuestChange = (e, index) => {
        console.log("handle guest change: ", eventData);
        let newForm = [...guestFields];
        newForm[index][e.target.name] = e.target.value;
        setGuestFields(newForm);
        eventData.guest = newForm;
    }

    function guestSwitchHandler(e) {
        setAddGuest(e.target.checked);
        setGuestFields([{ memberType: "Guest", firstName: "", lastName: "", mobileNumber: "" }]);
    }
    // Walkathon functions end

    const checkoutHandler = (e) => {
        e.preventDefault();
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function sendToServer() {

        console.log("transaction before sent to SQS:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    function feeSwitchHandler(e) {
        //Calculate total amount based on Credit card fee enabled check box. Add extra 3% to the total amount
        console.log('feeSwitchHandler : ' + e.target.checked);
        setFeeEnabled(e.target.checked);
    }

    function handleBack() {
        // User clicks on GO BACK button 
        console.log('Go back response:', eventData, tempData);
        setCheckout(false);
    }
    function handleChange(e) {
        //Handle any changes to the text fields
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    function volunteerSwitchHandler(e) {
        setAddVolunteer(e.target.checked);
        eventData.parent.volunteer = e.target.checked ? "true" : "false";
        console.log("Event data volunteerSwitchHandler :", (eventData.parent));
    };

    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
        console.log('Donation:', e.target.value, eventData, eventData.transaction.totalAmount)
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PROD_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        "disable-funding": "paylater",
    };

    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [orderId, setOrderId] = useState(false)
    const [checkout, setCheckout] = useState(false)


    const createOrder = (data, actions) => {
        var itemArray = [];

        if (eventData.transaction.quantities > 0 || noOfShirts > 0) {
            itemArray[itemArray.length] = {
                name: "TShirt, Holi Walkathon event amount",
                description: "TShirt, Holi Walkathon event amount",
                unit_amount: {
                    currency_code: "USD",
                    value: "" + (parseInt(noOfShirts) * parseInt(tshirtPrice) + parseInt(noOfPeople) * parseInt(picnicPrice)),
                },
                quantity: "1",
            }
        }
        if (eventData.transaction.donationAmount > 0) {
            itemArray[itemArray.length] = {
                name: "Donation Amount",
                description: "Donation item",
                unit_amount: {
                    currency_code: "USD",
                    value: eventData.transaction.donationAmount,
                },
                quantity: "1",
            }
        }
        if (gFeeEnabled > 0) {
            itemArray[itemArray.length] = {
                name: "Credit Card processing Fee Amount",
                description: "Credit Card Fee item",
                unit_amount: {
                    currency_code: "USD",
                    value: ((parseInt(noOfShirts) * parseInt(tshirtPrice) + parseInt(noOfPeople) * parseInt(picnicPrice) + parseInt(eventData.transaction.donationAmount)) * 0.03).toFixed(2),
                },
                quantity: "1",
            }
        }

        console.log('items array in create order 21:', itemArray, eventData.transaction.totalAmount, eventData.transaction.donationAmount, noOfPeople, noOfShirts, eventData.transaction.quantities);
        return actions.order.create({
            purchase_units: [
                {
                    description: "T-shirt Walkathon Event",
                    amount: {
                        value: eventData.transaction.totalAmount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                value: eventData.transaction.totalAmount,
                                currency_code: "USD",
                            }
                        }
                    },

                    items: itemArray, /**/
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        })
            .then((orderId) => {
                setOrderId(orderId)
                return orderId
            })
    }
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details
            setSuccess(true);
            setCheckout(true);
            eventData.transaction.paymentStatus = 'paid';
            eventData.transaction.payerId = payer.payer_id;
            console.log('on Approve Event data : ', eventData);
            sendToServer();
        })
    }
    const onError = (data, actions) => {
        setErrorMessage("An error occured with your payment")
    }
    return (
        <div>
            <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid> 

            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" spacing={2} alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Registration'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :
                                <Grid item>
                                    <span>
                                        <Box sx={{ width: '100%', maxWidth: 500 }}>
                                            <Typography variant="h6" align="left" gutterBottom>
                                                <b>If you want make any changes, please click "Back" button otherwise click "Paypal"
                                          button to complete the transactions</b>
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </span>
                                    <PayPalScriptProvider options={initialOptions}>
                                        <PayPalButtons style={{ layout: "vertical", label: "pay" }}
                                            createOrder={createOrder}
                                            onApprove={onApprove}
                                        />
                                    </PayPalScriptProvider>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <Grid item xs={12} sm={12}>
                                <Grid item>
                                    <Box sx={{ width: '100%', minWidth: 300 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            <b>Event:</b>CMDFW Annual Picnic/Holi, Walkathon, and T-shirt order
                                            <div><b>Where:</b>Chinmaya Mangalam | 10470 FM744, Barry TX, 75102</div>
                                            <div><b>When: </b>Saturday, March 29th, 2025</div>
                                            <div><b>COST per Person: </b>$15 - Picnic (Walkathon)</div>
                                            <div><b>T Shirt : </b> T-shirt available only at the center - ($15 after Feb 17th, $20 on Picnic Day)</div>
                                            <div><br /></div>
                                        </Typography>
                                    </Box>

                                </Grid>
                                <Grid item>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                                        <InputLabel id="demo-simple-select-standard-label">Select a Session</InputLabel>
                                        <Select
                                            id="standard"
                                            required
                                            name="center"
                                            value={eventData.center}
                                            onChange={e => setEventData({ ...eventData, [e.target.name]: e.target.value })}
                                            label="Center"
                                        >
                                            <MenuItem value="Chitrakoot-C1">Chitrakoot-C1</MenuItem>
                                            <MenuItem value="Chitrakoot-C3">Chitrakoot-C3</MenuItem>
                                            <MenuItem value="Frisco-F1">Frisco-F1</MenuItem>
                                            <MenuItem value="Frisco-F2">Frisco-F2</MenuItem>
                                            <MenuItem value="Frisco-F3">Frisco-F3</MenuItem>
                                            <MenuItem value="Saaket-S1">Saaket-S1</MenuItem>
                                            <MenuItem value="Saaket-S2">Saaket-S2</MenuItem>

                                        </Select>
                                    </FormControl>
                                    <div><br /></div>
                                </Grid>                               
                             

                                <Grid item xs={12} sm={12}>
                                    <ParentInfoComp eventData={eventData} />
                                </Grid>
                                
                                <div><br /></div>
                                {tshirtFlag && (                                  
                
                                    <Paper style={{ padding: '08px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h6" align="left" gutterBottom style={{ fontWeight: 'bold' }}>                                                                    
                                            <b>T-shirt Information</b>
                                        </Typography>                                            
                                    </Paper> 
                                )}
                                <div><br /></div>

                                {tshirtFlag && shirtFields.map((shirtFields, index) => (
                                    <div key={index} align="left">
                                        <Grid container spacing={4} >
                                            <Grid item xs={5} sm={4}>
                                                <FormControl variant="standard" sx={{ m: 1, minWidth: '100px' }} size="small">
                                                    <InputLabel id="demo-simple-select-standard-label"> Size</InputLabel>
                                                    <Select
                                                        id="standard"
                                                        name="shirtSize"
                                                        value={shirtFields.shirtSize}
                                                        onChange={(e) => handleShirtSize(e, index)}
                                                        label="Shirtsize"

                                                    >
                                                        <MenuItem value="Adult-XL">Adult-XL</MenuItem>
                                                        <MenuItem value="Adult-L">Adult-L</MenuItem>
                                                        <MenuItem value="Adult-M">Adult-M</MenuItem>
                                                        <MenuItem value="Adult-S">Adult-S</MenuItem>
                                                        <MenuItem value="Youth-L">Youth-L</MenuItem>
                                                        <MenuItem value="Youth-M">Youth-M</MenuItem>
                                                        <MenuItem value="Youth-S">Youth-S</MenuItem>
                                                        <MenuItem value="Youth-XS">Youth-XS</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} sm={3}>
                                                <TextField
                                                    number
                                                    id="noOfShirts"
                                                    label="# of Shirts"
                                                    style={{ m: 1, minWidth: '100px' }}
                                                    inputProps={{ min: 0, max: 10 }}
                                                    type="number"
                                                    name="shirtQuantity"
                                                    variant="standard"
                                                    value={shirtFields.shirtQuantity}
                                                    onChange={(e) => handleShirtQuantity(e, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={4} sm={3}>
                                                {index >= 1 ?
                                                    <IconButton onClick={() => handleRemoveShirtFields(index)}>
                                                        <DeleteIcon />
                                                    </IconButton> : ''
                                                }
                                                <IconButton onClick={() => handleAddShirtFields(index)}>
                                                    <PersonAddIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}


                                <div><br /></div>
                                {holiFlag && (

                                    <Paper style={{ padding: '08px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h6" align="left" gutterBottom style={{ fontWeight: 'bold' }}>                                                                    
                                            <b>Picnic/Holi Walkathon Registration</b>
                                        </Typography>                                            
                                    </Paper> 
                               
                                )}
                                <Grid container >
                                    <Grid item>
                                    {holiFlag && (
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={addPeople} />} label="Register for Holi Walkathon / Picnic" size="small"
                                                onChange={peopleSwitchHandler} />
                                        </FormGroup>
                                         )}
                                    </Grid>
                                    <Grid item>
                                        <Divider variant="middle" />
                                    </Grid>
                                    <Grid container spacing={2} alignItems="left" >
                                        <Grid item xs={8} sm={6}>
                                            {holiFlag && addPeople && (
                                                <TextField
                                                    fullWidth
                                                    id="noOfPeople"
                                                    label="# of participants (including me)"
                                                    sx={{ m: 1, minWidth: 150 }}
                                                    inputProps={{ min: 0, max: 10 }}
                                                    type="number"
                                                    name="noOfPeople"
                                                    variant="standard"
                                                    value={noOfPeople}
                                                    onChange={handlePeople}
                                                />)}
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>

                                        {addPeople && noOfPeople >= 2 &&
                                            <FormGroup>
                                                <FormControlLabel control={<Switch checked={addGuest} />} label="Add Family/Guest ( Optional)" size="small" onChange={guestSwitchHandler} />
                                            </FormGroup>
                                        }

                                        {addPeople && addGuest && noOfPeople >= 2 && guestFields.map((guestFields, index) => (
                                            <div key={index} align="left">
                                                <Grid container spacing={2} alignItems="left">
                                                    <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            id="firstName"
                                                            name="firstName"
                                                            label="First Name"
                                                            variant="standard"
                                                            value={guestFields.firstName}
                                                            onChange={(e) => handleGuestChange(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            fullWidth
                                                            id="lastName"
                                                            name="lastName"
                                                            label="Last Name"
                                                            variant="standard"
                                                            value={guestFields.lastName}
                                                            onChange={(e) => handleGuestChange(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} sm={8}>
                                                        <TextField
                                                            id="mobileNumber"
                                                            type="number"

                                                            name="mobileNumber"
                                                            label="Mobile Phone"
                                                            variant="standard"
                                                            value={guestFields.mobileNumber}
                                                            onChange={(e) => handleGuestChange(e, index)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} sm={4}>
                                                        <IconButton onClick={() => handleRemoveFields(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => handleAddFields(index)}>
                                                            <PersonAddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ))}
                                    </Grid>
                                </Grid>
                                <div><br /></div>
                                <div><br /></div>


                                <Paper style={{ padding: '08px', backgroundColor: '#673ab7', color: 'white' }} elevation={3}>
                                        <Typography variant="h6" align="left" gutterBottom style={{ fontWeight: 'bold' }}>                                                                    
                                            <b>Payment Information</b>
                                        </Typography>                                            
                                </Paper>  
                                
                                <div><br /></div>
                                <Grid container direction="row" spacing={2} alignItems="center" >
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            number
                                            id="donation"
                                            label="Additional Donation ($)"
                                            type="number"
                                            name="donation"
                                            variant="standard"
                                            value={donation}
                                            onChange={handleDonation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={feeEnabled} />} label="Add 3% to cover for CC fees"
                                                value={feeEnabled} size="small" onChange={feeSwitchHandler} />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="totalAmountField"
                                            label="Total Amount($)"
                                            type="number"
                                            name="totalAmount"
                                            variant="standard"
                                            inputProps={{ style: { fontSize: 20 } }} // font size of input text
                                            InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
                                            value={totalAmount}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container direction="column" spacing={5}>

                                    <Grid item>
                                        <TextField
                                            id="notes"
                                            fullWidth
                                            multiline
                                            label="Notes (Special needs, Food allergies):"
                                            name="notes"
                                            variant="standard"
                                            value={eventData.notes}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {holiFlag && (
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Checkout</Button>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" align="center" gutterBottom>
                                            If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </form>


        </div>
    );
}