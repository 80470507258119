import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Switch from "@mui/material/Switch";
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import GuestComp from '../components/GuestComp'
import ChildCareComp from '../components/ChildCareComp'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';
import { Paper } from '@mui/material';

import StudentInfoComp from '../components/StudentInfoComp'
import ParentInfoComp from '../components/ParentInfoComp'




var gFeeEnabled = {};

export default function GitaFestForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: "",
            emergencyContactName: "",
            emergencyContactMobile: "",
            gitaFestDate: ""
        },
        spouse: {},
        children: [],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {},
        tshirts: [],
        eventType: 'GitaFest',
        eventTypeId: 6,
        center: '',
        notes: '',
        category: '',
        sessionName: ""
    });
    //    eventData.transaction.itemPrice = process.env.REACT_APP_GITAFEST_PRICE;
    const ticketPrice = process.env.REACT_APP_GITAFEST_PRICE;

    console.log("Event data in GiaFest form :", JSON.stringify(eventData), eventData);
    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [parentDate, setParentDate] = useState("");
    const [childCategory, setChildCategory] = useState(false);
    const [noOfPeople, setNoOfPeople] = useState(1);
    const [spouseFields, setSpouseFields] = useState(eventData.spouse);
    const [studentFields, setStudentFields] = useState(eventData.children);
    const [addSpouse, setAddSpouse] = useState(false);
    const [feeEnabled, setFeeEnabled] = useState(0);
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(inputFields.quantities * inputFields.itemPrice);
    //console.log("Donation, totalamounts at the begin:", (eventData), inputFields, donation, totalAmount);

    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
        eventData.spouse = spouseFields;
        eventData.children = studentFields;
        eventData.parent.gitaFestDate = parentDate;
        gFeeEnabled = feeEnabled;
    }
    total();
    //console.log("Donation, totalamounts at the end:", (eventData), inputFields, donation, totalAmount);

    function handleCategoryChange(e) {
        setEventData({ ...eventData, [e.target.name]: e.target.value });
        setNoOfPeople(1);
        if (e.target.value === 'ChildrenCompetition') {
            setChildCategory(true);
            setStudentFields([{
                memberType: "child",
                firstName: "",
                lastName: "",
                grade: "",
                gitaFestDate: "04/20/2024"
            }]);
            setSpouseFields({});
            setAddSpouse(false);
        } else {
            setChildCategory(false);
            setStudentFields([]);

        }
    }

    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = ticketPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.00);
        }

        eventData.transaction.totalAmount = totalAmount;
    }, [noOfPeople, donation, feeEnabled])


    const handleStudentChange = (e, index) => {
        //    console.log("Event data in Student handle form :", (studentFields));        
        let newForm = [...studentFields];
        newForm[index][e.target.name] = e.target.value;
        setStudentFields(newForm);
        eventData.children = newForm;
    }
    const handleAddFields = () => {
        setStudentFields([...studentFields, {
            memberType: "child", firstName: "", lastName: "", grade: "", gitaFestDate: "04/20/2024"
        }]);
        setNoOfPeople(noOfPeople + 1);
    };

    const handleRemoveFields = (index) => {
        const values = [...studentFields];
        values.splice(index, 1);
        setStudentFields(values);
        eventData.children = values;
        setNoOfPeople(noOfPeople - 1);
    };

    function handleSpouseChange(e) {
        let newForm = { ...spouseFields };
        newForm[e.target.name] = e.target.value;
        setSpouseFields(newForm);
        eventData.spouse = newForm;
    }

    function spouseSwitchHandler(e) {
        setAddSpouse(e.target.checked);
        if (e.target.checked) {
            setNoOfPeople(2);
            setSpouseFields({
                memberType: "spouse",
                firstName: "",
                lastName: "",
                mobileNumber: "",
                email: "",
                gitaFestDate: ""
            });
        } else { setNoOfPeople(1) };

    }

    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function sendToServer() {
        console.log("transaction before sent to SQS:", (eventData));

        try {
            console.log("env", process.env)

            const url = process.env.REACT_APP_SQS_URL;

            //const url = "https://sitmj29go3.execute-api.us-east-1.amazonaws.com/dev/testEvents";
            console.log("URL to SQS:", (url));


            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }

    function feeSwitchHandler(e) {
        //Calculate total amount based on Credit card fee enabled check box. Add extra 3% to the total amount
        setFeeEnabled(e.target.checked);
    }

    function handleBack() {
        // User clicks on GO BACK button 
        setCheckout(false);
    }

    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
    };

    const initialOptions = {
        clientId: process.env.REACT_APP_PROD_CLIENT_ID,
        currency: "USD",
        intent: "capture",
        "disable-funding": "paylater",
    };

    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [orderId, setOrderId] = useState(false)
    const [checkout, setCheckout] = useState(false)


    const createOrder = (data, actions) => {
        var itemArray = [];

        if (eventData.transaction.quantities > 0) {
            itemArray[itemArray.length] = {
                name: "Gita Chanting Competition Event Amount",
                description: "Gita Chanting Competition Event Amount",
                unit_amount: {
                    currency_code: "USD",
                    value: "" + parseInt(inputFields.itemPrice * inputFields.quantities),
                },
                quantity: "1",
            }
        }
        console.log('items array donation:', eventData.transaction.donationAmount);
        if (eventData.transaction.donationAmount > 0) {
            itemArray[itemArray.length] = {
                name: "Donation Amount",
                description: "Donation item",
                unit_amount: {
                    currency_code: "USD",
                    value: eventData.transaction.donationAmount,
                },
                quantity: "1",
            }
        }
        console.log('items array fee:', gFeeEnabled);
        if (gFeeEnabled > 0) {
            itemArray[itemArray.length] = {
                name: "Credit Card processing Fee Amount",
                description: "Credit Card Fee item",
                unit_amount: {
                    currency_code: "USD",
                    value: ((parseInt(inputFields.quantities) * inputFields.itemPrice + parseInt(eventData.transaction.donationAmount)) * 0.03).toFixed(2),
                },
                quantity: "1",
            }
        }
        console.log('items array in create order 2:', itemArray, eventData.transaction.totalAmount, eventData.transaction.donationAmount);
        return actions.order.create({
            purchase_units: [
                {
                    description: "Gita Chanting Competition Event",
                    amount: {
                        value: eventData.transaction.totalAmount,
                        currency_code: "USD",
                        breakdown: {
                            item_total: {
                                value: eventData.transaction.totalAmount,
                                currency_code: "USD",
                            }
                        }
                    },

                    items: itemArray, /**/
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING"
            }
        })
            .then((orderId) => {
                setOrderId(orderId)
                return orderId
            })
    }
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            const { payer } = details
            setSuccess(true);
            setCheckout(true);
            eventData.transaction.paymentStatus = 'paid';
            eventData.transaction.payerId = payer.payer_id;
            console.log('on Approve Event data : ', eventData);
            sendToServer();
        })
    }
    const onError = (data, actions) => {
        setErrorMessage("An error occured with your payment")
    }

    return (
        <div>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid> 

            <form onSubmit={checkoutHandler}>
                <Grid container direction="column" alignItems="center" >
                    {
                        checkout ?
                            success ?
                                <Grid item>
                                    <img src={successIcon} className="Success-icon" alt="logo" />
                                    <Typography variant="h5" align="center" gutterBottom>
                                        {'Thank You for the Registration'}
                                    </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                        <Typography variant="body2" align="left" gutterBottom>
                                            You will receive an email confirmation to this address <b> [ {eventData.parent.email} ] </b>
                                        </Typography>
                                    </Box>
                                </Grid>
                                :

                                <Grid item>
                                    <span>
                                        <Box sx={{ width: '100%', maxWidth: 500 }}>
                                            <Typography variant="h6" align="left" gutterBottom>
                                                <b>If you want to make any changes, please click "Back" button otherwise click "Paypal"
                                          button to complete the transactions</b>
                                                <div><br /></div>
                                            </Typography>
                                        </Box>
                                    </span>
                                    <PayPalScriptProvider options={initialOptions}>
                                        <PayPalButtons style={{ layout: "vertical", label: "pay" }}
                                            createOrder={createOrder}
                                            onApprove={onApprove}

                                        />
                                    </PayPalScriptProvider>
                                    <span>
                                        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleBack()}>Back</Button>
                                    </span>
                                </Grid>
                            :
                            <div>
                                <Grid item xs={12} sm={12}>
                                    <Grid item xs={12} sm={12}>
                                    <Paper elevation={3}>
                                    <Paper style={{ padding: '16px', backgroundColor: '#dfe7e7', color: 'black' }} elevation={3}>
                                        <Grid item>
                                            <Box sx={{ width: '100%', minWidth: 300 }}>
                                                <Typography variant="body2" align="left" gutterBottom>

                                                    <div><b>Event:</b> Gita Chanting Competition</div>
                                                    <b>Where: </b>Children – April 26th  @ Chinmaya Chitrakoot (9:30AM-2:00PM)
                                                    <div>Adult – April 25th  @ Chinmaya Saaket (7:00 PM - 9:00 PM)</div>
                                                    <b>COST: </b>${ticketPrice} /  per Participant
                                                    <div><br /></div>
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        </Paper>
                                        </Paper>
                                    </Grid>
                                    <Grid item>
                                        <Grid container alignItems="left" spacing={3}>
                                            <Grid item xs={12} sm={5}>
                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }} >
                                                    <InputLabel id="demo-simple-select-standard-label">Select a Session</InputLabel>
                                                    <Select
                                                        id="standard"
                                                        required
                                                        name="center"
                                                        value={eventData.center}
                                                        onChange={e => setEventData({ ...eventData, [e.target.name]: e.target.value })}
                                                        label="Center"
                                                    >
                                                        <MenuItem value="Chitrakoot-C1">Chitrakoot-C1</MenuItem>
                                                        <MenuItem value="Chitrakoot-C2">Chitrakoot-C2</MenuItem>
                                                        <MenuItem value="Frisco-F1">Frisco-F1</MenuItem>
                                                        <MenuItem value="Frisco-F2">Frisco-F2</MenuItem>
                                                        <MenuItem value="Frisco-F3">Frisco-F3</MenuItem>
                                                        <MenuItem value="Saaket-S1">Saaket-S1</MenuItem>
                                                        <MenuItem value="Saaket-S2">Saaket-S2</MenuItem>
                                                        <MenuItem value="Other">Other</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <div><br /></div>
                                            </Grid>
                                            <Grid item xs={12} sm={7}>
                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }} >
                                                    <InputLabel id="demo-simple-select-standard-label">Select a Category</InputLabel>
                                                    <Select
                                                        id="standard"
                                                        required
                                                        name="category"
                                                        label="Category"
                                                        value={eventData.category}
                                                        onChange={handleCategoryChange}>
                                                        <MenuItem value="AdultCompetition">Adult Competition</MenuItem>
                                                        <MenuItem value="ChildrenCompetition">Children Competition</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <div><br /></div>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                            {childCategory && (

                                                <Grid container alignItems="center" >
                                                    <Typography variant="h7" align="left" gutterBottom>
                                                        <b>Bala Vihar Student Information</b>
                                                    </Typography>
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container align="left" >
                                                            {studentFields.map((studentFields, index) => (
                                                                <div key={index} align="left">
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={12} sm={6}>
                                                                            <TextField
                                                                                required
                                                                                fullWidth
                                                                                id="fName"
                                                                                label="First Name"
                                                                                name="firstName"
                                                                                variant="standard"
                                                                                value={studentFields.firstName}
                                                                                onChange={(e) => handleStudentChange(e, index)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={6}>
                                                                            <TextField
                                                                                required
                                                                                fullWidth
                                                                                id="lName"
                                                                                label="Last Name"
                                                                                name="lastName"
                                                                                variant="standard"
                                                                                value={studentFields.lastName}
                                                                                onChange={(e) => handleStudentChange(e, index)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6} sm={6}>
                                                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }} size="small">
                                                                                <InputLabel id="demo-simple-select-standard-label">Grade</InputLabel>
                                                                                <Select
                                                                                    id="grade"
                                                                                    name="grade"
                                                                                    value={studentFields.grade}
                                                                                    onChange={(e) => handleStudentChange(e, index)}
                                                                                    label="Grade"
                                                                                >
                                                                                <MenuItem value="KG">KG</MenuItem>
                                                                                <MenuItem value="Grade-1">Grade-1</MenuItem>
                                                                                <MenuItem value="Grade-2">Grade-2</MenuItem>
                                                                                <MenuItem value="Grade-3">Grade-3</MenuItem>
                                                                                <MenuItem value="Grade-4">Grade-4</MenuItem>
                                                                                <MenuItem value="Grade-5">Grade-5</MenuItem>
                                                                                <MenuItem value="Grade-6">Grade-6</MenuItem>
                                                                                <MenuItem value="Grade-7">Grade-7</MenuItem>
                                                                                <MenuItem value="Grade-8">Grade-8</MenuItem>
                                                                                <MenuItem value="Grade-9">Grade-9</MenuItem>
                                                                                <MenuItem value="Grade-10">Grade-10</MenuItem>
                                                                                <MenuItem value="Grade-11">Grade-11</MenuItem>
                                                                                <MenuItem value="Grade-12">Grade-12</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <div><br /></div>
                                                                            <b>Add/Delete Additional students</b>
                                                                            {index >= 1 ?
                                                                                <IconButton onClick={() => handleRemoveFields(index)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton> : ''
                                                                            }
                                                                            <IconButton onClick={() => handleAddFields(index)}>
                                                                                <PersonAddIcon />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            ))}

                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            )}
                                    </Grid>
                                    <div><br /></div>
                                </Grid>
                                <Typography variant="h6" align="left" gutterBottom>
                                    <b>Primary Contact</b>
                                </Typography>
                                <ParentInfoComp eventData={eventData} />
                                {!childCategory && (
                                    
                                    <Grid item xs={12}>
                                         <div><br /></div>
                                        <Grid container spacing={1} alignItems="left">
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                                            <InputLabel id="demo-simple-select-standard-label"> Participation Date</InputLabel>
                                            <Select
                                                id="standard"
                                                required
                                                name="gitaFestDate"
                                                label="gitaFestDate"
                                                value={parentDate}
                                                onChange={e => setParentDate(e.target.value)}
                                            >
                                                <MenuItem value="04/25/2025">April 25th - Friday</MenuItem>
                                            </Select>
                                        </FormControl>
                                        </Grid>
                                    </Grid>
                                )}

                                {!childCategory && (
                                    < Grid item xs={12}>
                                        <div><br /></div>
                                        <FormGroup>
                                            <FormControlLabel control={<Switch checked={addSpouse} />} label="Add/Delete Spouse ( Optional)"
                                                size="small" onChange={spouseSwitchHandler} />
                                        </FormGroup>
                                    </Grid>
                                )}


                                {!childCategory && addSpouse && (
                                    <Grid container spacing={2} alignItems="left">
                                        <Grid item xs={6} sm={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="firstName"
                                                name="firstName"
                                                label="First Name"
                                                variant="standard"
                                                value={spouseFields.firstName}
                                                onChange={handleSpouseChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <TextField
                                                fullWidth
                                                required
                                                id="lastName"
                                                name="lastName"
                                                label="Last Name"
                                                variant="standard"
                                                value={spouseFields.lastName}
                                                onChange={handleSpouseChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="mobileNumber"
                                                fullWidth
                                                required
                                                type="number"
                                                name="mobileNumber"
                                                label="Mobile Phone"
                                                variant="standard"
                                                value={spouseFields.mobileNumber}
                                                onChange={handleSpouseChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                type="email"
                                                fullWidth
                                                required
                                                id="email"
                                                label="Email"
                                                variant="standard"
                                                name="email"
                                                value={spouseFields.email}
                                                onChange={handleSpouseChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1} alignItems="left">
                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small" alignItems="left">
                                                    <InputLabel id="demo-simple-select-standard-label"> Participation Date</InputLabel>
                                                    <Select
                                                        id="standard"
                                                        required
                                                        name="gitaFestDate"
                                                        label="gitaFestDate"
                                                        value={spouseFields.gitaFestDate}
                                                        onChange={handleSpouseChange}
                                                    >
                                                        <MenuItem value="04/16/2024">April 16th - Tuesday</MenuItem>
                                                        <MenuItem value="04/17/2024">April 17th - Wednesday</MenuItem>
                                                        <MenuItem value="04/18/2024">April 18th - Thursday</MenuItem>
                                                        <MenuItem value="04/19/2024">April 19th - Friday</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                <div><br /><br /></div>
                                <Grid item>
                                    <Grid item>
                                    <Typography variant="h6" align="left" gutterBottom>
                                        <b>Payment Information</b>
                                    </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Divider variant="middle" />
                                    </Grid>
                                    <div><br /></div>
                                    <Grid container direction="row" spacing={2} alignItems="left" v>
                                        <Grid item>
                                            <TextField
                                                id="Quantity"
                                                disabled
                                                label="Quantities #"
                                                type="number"
                                                name="Quantities"
                                                variant="standard"
                                                value={inputFields.quantities}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="donation"
                                                label="Additional Donation ($)"
                                                type="number"
                                                name="donation"
                                                variant="standard"
                                                value={donation}
                                                onChange={handleDonation}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Grid container direction="row" spacing={2} alignItems="center" v>
                                        <Grid item>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={feeEnabled} />} label="Add additional 3% to cover for CC fees"
                                                    value={feeEnabled} size="small" onChange={feeSwitchHandler} />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                disabled
                                                id="totalAmount"
                                                label="Total Amount($)"
                                                type="number"
                                                name="totalAmount"
                                                variant="standard"
                                                inputProps={{ style: { fontSize: 20 } }} // font size of input text
                                                InputLabelProps={{ style: { fontSize: 20 } }} // font size of input label
                                                value={totalAmount}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <div><br /></div>

                                <Grid container direction="column" spacing={2} alignItems="center" >
                                    <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Checkout</Button>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" align="center" gutterBottom>
                                        If you have any questions, please contact us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a>
                                    </Typography>
                                </Grid>
                            </div>
                    }
                </Grid>
            </form>
        </div >
    );
}